import React from 'react';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import { gql, useMutation, useQuery } from '@apollo/client';
import {
    Box,
    Button,
    CircularProgress,
    Container,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Stack,
    Typography
} from '@mui/material';
import { EmailSetup } from './RoleEmailPage';
import { CheckCircle, HourglassBottomTwoTone, Replay, SendRounded } from '@mui/icons-material';
import CloseIcon from '@mui/icons-material/Close';

export default function SendEmail({ selectedIds, eventId, role }) {
    const [open, setOpen] = React.useState(false);
    const [scroll, setScroll] = React.useState('paper');
    // const close = () => setOpen(false);
    const handleClickOpen = (scrollType) => () => {
        setOpen(true);
        setScroll(scrollType);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const descriptionElementRef = React.useRef(null);
    React.useEffect(() => {
        if (open) {
            const { current: descriptionElement } = descriptionElementRef;
            if (descriptionElement !== null) {
                descriptionElement.focus();
            }
        }
    }, [open]);

    return (
        <>
            <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose} scroll={scroll}>
                <Stack direction="row" justifyContent={'space-between'} alignItems={'center'} paddingX={2} bgcolor={'#ffe57f'}>
                    <DialogTitle sx={{ fontSize: 18 }}>Send Email</DialogTitle>
                    <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                </Stack>
                <DialogContent dividers={scroll === 'paper'}>
                    <DialogContentText id="scroll-dialog-description" ref={descriptionElementRef} tabIndex={-1}>
                        <Box p={2}>
                            <ConfigureEmailFor {...{ selectedIds, eventId, role }} />
                        </Box>
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {selectedIds.length > 0 && (
                <Button variant="contained" color="error" onClick={handleClickOpen('paper')}>
                    Send Mail ({selectedIds.length})
                </Button>
            )}
        </>
    );
}

function ConfigureEmailFor({ eventId, role, selectedIds }) {
    const { data, loading } = useQuery(GET_EMAIL, {
        variables: { eventId, role },
        onError: (e) => console.log(JSON.stringify(e, null, 2))
    });
    const [step, setStep] = React.useState(0);
    const [email, setEmail] = React.useState({});
    const handleSubmit = (f) => {
        const { senderName, senderEmail, senderEmailPassword, senderHost, senderSubject, senderBody, enquiryBody, to } = f;
        setEmail({
            senderName,
            senderEmail,
            senderEmailPassword,
            senderHost,
            senderSubject,
            senderBody,
            enquiryBody,
            to
        });
        setStep((s) => s + 1);
    };
    return (
        <Container>
            <Stepper activeStep={step}>
                <Step>
                    <StepLabel>Configure Email</StepLabel>
                </Step>
                <Step>
                    <StepLabel>Sent</StepLabel>
                </Step>
                {/* <Step>
          <StepLabel>Report</StepLabel>
        </Step> */}
            </Stepper>
            <Box p={2} />
            {step === 0 ? (
                <>
                    {loading ? (
                        <LinearProgress />
                    ) : (
                        <EmailSetup title="" eventId={eventId} role={role} onSubmit={handleSubmit} defaultValues={data?.email} />
                    )}
                </>
            ) : (
                <SendingEmailList {...{ email, selectedIds, eventId, role }} onDone={() => setStep((s) => s + 1)} />
            )}
        </Container>
    );
}

function SendingEmailList({ email, selectedIds, eventId, role, onDone }) {
    const [done, setDone] = React.useState(0);
    const MemoItem = React.memo(Item, (prev, next) => prev.id === next.id);
    const handleDone = () => {
        setTimeout(() => {
            setDone((s) => s + 1);
        }, 3000);
    };

    React.useEffect(() => {
        if (done === selectedIds?.length) {
            onDone();
        }
    }, [done]);

    return (
        <>
            <List>
                {selectedIds?.map(({ id, email: address }, key) => (
                    <Item key={key} {...{ email, eventId, role, id, address }} onDone={handleDone} allowSend={done === key} />
                ))}
            </List>
        </>
    );
}

const Item = React.memo(function ({ id, address, onDone, email, eventId, role, allowSend }) {
    const [send, { data, loading, error }] = useMutation(SEND_EMAIL, {
        variables: {
            eventId,
            role,
            email,
            id
        }
    });
    React.useEffect(() => {
        if (!allowSend) return;

        const t = setTimeout(() => {
            // console.log('sending', id);
            send().then(() => onDone(id));
            // onDone(id);
        }, 300);
        return () => clearTimeout(t);
    }, [allowSend]);

    if (loading) {
        return (
            <ListItem>
                <ListItemText primary={id} secondary={address} />
                <ListItemIcon>
                    <HourglassBottomTwoTone />
                </ListItemIcon>
            </ListItem>
        );
    }
    if (data?.result) {
        return (
            <ListItem>
                <ListItemText primary={id} secondary={address} />
                <ListItemIcon>
                    <CheckCircle color="success" />
                </ListItemIcon>
            </ListItem>
        );
    }
    return (
        <ListItemButton onClick={() => send()}>
            <ListItemText primary={id} secondary={address} />
            {error && (
                <Box
                    sx={{
                        maxWidth: 400
                    }}
                >
                    {error.message}
                </Box>
            )}
            <ListItemIcon>
                <HourglassBottomTwoTone />
            </ListItemIcon>
        </ListItemButton>
    );
});

const GET_EMAIL = gql`
    query ($eventId: String!, $role: ROLE!) {
        email(eventId: $eventId, role: $role) {
            id
            role
            senderName
            senderEmail
            senderEmailPassword
            senderHost
            senderSubject
            senderBody
            senderAcceptBody
            senderRejectBody
            enquiryBody
            to
            created
            modified
        }
    }
`;

const SEND_EMAIL = gql`
    mutation ($eventId: String!, $role: ROLE!, $email: EmailInput!, $id: String!) {
        result: sendEmail(eventId: $eventId, role: $role, email: $email, id: $id)
    }
`;
