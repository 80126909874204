import React from 'react';
import { useNavigate } from 'react-router-dom';

export default function LogoutPage() {
    // console.log('logout page called');
    const navigate = useNavigate();
    React.useEffect(() => {
        // localStorage.clear();
        sessionStorage.clear();
        navigate('/', { replace: true });
    }, []);

    return <div>Please wait...</div>;
}
