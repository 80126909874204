import { useRoutes } from 'react-router-dom';

// routes
import MainRoutes from './MainRoutes';
import AuthenticationRoutes from './AuthenticationRoutes';
import ProtectedRoutes from './ProtectedRoutes';

// ==============================|| ROUTING RENDER ||============================== //

export default function ThemeRoutes() {
    const isAuthenticated = sessionStorage.getItem('auth.reception.eventegration.com');
    // console.log(isAuthenticated);
    return useRoutes(!isAuthenticated ? [AuthenticationRoutes] : [MainRoutes]);
}

function NotFound() {
    return <div>Page not found</div>;
}
